import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appLongPress]'
})
export class LongPressDirective {
  @Output() appLongPress = new EventEmitter<void>();

  private timer: any;
  private readonly pressTime = 500; // Time in ms for long press detection
  private startX = 0;
  private startY = 0;
  private moved = false; // Track movement

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onPressStart(event: MouseEvent | TouchEvent) {
    this.moved = false; // Reset movement flag

    // Get initial touch position
    if (event instanceof TouchEvent) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    } else {
      this.startX = event.clientX;
      this.startY = event.clientY;
    }

    // Start timer for long press
    this.timer = setTimeout(() => {
      if (!this.moved) {
        this.appLongPress.emit();
      }
    }, this.pressTime);
  }

  @HostListener('touchmove', ['$event'])
  @HostListener('mousemove', ['$event'])
  onPressMove(event: MouseEvent | TouchEvent) {
    let currentX, currentY;

    if (event instanceof TouchEvent) {
      currentX = event.touches[0].clientX;
      currentY = event.touches[0].clientY;
    } else {
      currentX = event.clientX;
      currentY = event.clientY;
    }

    // If movement is significant, cancel long press
    if (Math.abs(currentX - this.startX) > 10 || Math.abs(currentY - this.startY) > 10) {
      this.moved = true;
      this.clearPress();
    }
  }

  @HostListener('mouseup') 
  @HostListener('mouseleave') 
  @HostListener('touchend') 
  @HostListener('touchcancel')
  onPressEnd() {
    this.clearPress();
  }

  private clearPress() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}