import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChangePasswordDto } from '../../../models/change-password.model';
import { BaseResponse } from '../../../models/http.model';
import { ProfileDto } from '../../../models/user.model';
import { HttpService } from '../../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private isHeaderVisibleSubject = new BehaviorSubject<boolean>(true);
  isHeaderVisible$ = this.isHeaderVisibleSubject.asObservable();

  private titleSubject = new BehaviorSubject<string>('');
  title$ = this.titleSubject.asObservable();

  private descriptionSubject = new BehaviorSubject<string>('');
  description$ = this.descriptionSubject.asObservable();

  private isBannerVisibleSubject = new BehaviorSubject<boolean>(true);
  isBannerVisible$ = this.isBannerVisibleSubject.asObservable();

  constructor(
    private httpService: HttpService
  ) { }

  showHeader() {
    this.isHeaderVisibleSubject.next(true);
  }

  hideHeader() {
    this.isHeaderVisibleSubject.next(false);
  }

  setTitle(title: string) {
    this.titleSubject.next(title);
  }

  setDescription(description: string) {
    this.descriptionSubject.next(description);
  }

  showBanner() {
    this.isBannerVisibleSubject.next(true);
  }

  hideBanner() {
    this.isBannerVisibleSubject.next(false);
  }

  changePassword(changePasswordDto: ChangePasswordDto): Observable<BaseResponse> {
    return this.httpService.forClientApi().post('/user/account/password/change', changePasswordDto);
  }

  getProfile(): Observable<BaseResponse> {
    return this.httpService.forClientApi().get('/user/profile?t=' + new Date().getTime());
  }

  updateProfile(profileDto: ProfileDto): Observable<BaseResponse> {
    return this.httpService.forClientApi().post('/user/profile', profileDto);
  }

  getContactDetails(): Observable<BaseResponse> {
    return this.httpService.forClientApi().get('/user/contact-details?t=' + new Date().getTime());
  }

}
