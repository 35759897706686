import { Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

const _30daysExpiration: number = 30;
const defaultCookieSettings: object  = {
  expires: _30daysExpiration,
  sameSite: 'Strict',
  path: '/',
  partitioned: undefined,
  domain: undefined,
  secure: undefined
};

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor(
    private ssrCookieService: SsrCookieService
  ) { }
  
  setWelcomeScreenShown(): void {
    this.ssrCookieService.set('welcomeScreenShown', 'X', defaultCookieSettings);
  }

  getIsWelcomeScreenShown(): boolean {
    return this.ssrCookieService.get('welcomeScreenShown') != null;
  }
  
  setRememberedLoginName(loginName: string): void {
    this.ssrCookieService.set('rememberMeToken', loginName, defaultCookieSettings);
  }

  getRememberedLoginName(): string {
    return this.ssrCookieService.get('rememberMeToken');
  }

  setCurrentUserLoginName(loginName: string): void {
    this.ssrCookieService.set('currentUserLoginName', loginName, defaultCookieSettings);
  }
  
  getCurrentUserLoginName(): string {
    return this.ssrCookieService.get('currentUserLoginName');
  }

  getLoginCaptchaToken(): string {
    return this.ssrCookieService.get('loginCaptchaToken');
  }

  setLoginCaptchaToken(captchaToken: string) {
    this.ssrCookieService.set('loginCaptchaToken', captchaToken, {
      ...defaultCookieSettings,
      expires: 1
    });
  }

  setAccessToken(accessToken: string) {
    const fiveHoursExpiration = new Date(Date.now() + 5 * 60 * 60 * 1000);
    this.ssrCookieService.set('accessToken', accessToken, {
      ...defaultCookieSettings,
      expires: fiveHoursExpiration,
    });
  }

  getAccessToken(): string {
    return this.ssrCookieService.get('accessToken');
  }

  deleteAccessToken(): void {
    this.ssrCookieService.delete('accessToken', '/');
  }

  setOtpBypassToken(otpBypassToken: string) {
    const fifteenMinutesExpiration = new Date(Date.now() + 15 * 60 * 1000);
    this.ssrCookieService.set('otpBypassToken', otpBypassToken, {
      ...defaultCookieSettings,
      expires: fifteenMinutesExpiration,
    });
  }

  getOtpBypassToken(): string {
    return this.ssrCookieService.get('otpBypassToken');
  }

  deleteOtpBypassToken(): void {
    this.ssrCookieService.delete('otpBypassToken', '/');
  }

  setHiggsJwt(higgsJwt: string) {
    this.ssrCookieService.set('higgsJwt', higgsJwt, defaultCookieSettings);
  }

  getHiggsJwt(): string {
    return this.ssrCookieService.get('higgsJwt');
  }

  setScanAccessToken(accessToken: string) {
    this.ssrCookieService.set('scanAccessToken', accessToken, defaultCookieSettings);
  }

  getScanAccessToken(): string {
    return this.ssrCookieService.get('scanAccessToken');
  }

  setChatGroupSearchTerm(searchTerm: string) {
    this.ssrCookieService.set('chat-group-search-term', searchTerm, {
      ...defaultCookieSettings,
      expires: 0
    });
  }

  getChatGroupSearchTerm() {
    return this.ssrCookieService.get('chat-group-search-term');
  }
}
